import { arrayRange } from './helpers';

const BOOKS_FOR_PAGE = 15;
const GENRES_FOR_PAGE = 30;
const SERIES_FOR_PAGE = 30;
const AUTHORS_FOR_PAGE = 30;
const PRERENDER_PAGES = 2;

const RUSSIAN_ALPHABET = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];

const DAYS = arrayRange(1, 31, 1);
const MONTHS = [
  { name: 'Январь', value: '01' },
  { name: 'Февраль', value: '02' },
  { name: 'Март', value: '03' },
  { name: 'Апрель', value: '04' },
  { name: 'Май', value: '05' },
  { name: 'Июнь', value: '06' },
  { name: 'Июль', value: '07' },
  { name: 'Август', value: '08' },
  { name: 'Сентябрь', value: '09' },
  { name: 'Октябрь', value: '10' },
  { name: 'Ноябрь', value: '11' },
  { name: 'Декабрь', value: '12' },
];

const MONTH_NAMES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

const YEARS = arrayRange(1923, 2018, 1);
const GENDER = [
  { name: 'Мужской', value: 'male' },
  { name: 'Женский', value: 'female' },
];

const DEV_ABSOLUTE_URL = 'https://fantasy-worlds.ru';

const config = {
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization',
  },
};

const API_URL = `${process.env.APP_URL}`;

const REVALIDATE_TIME = 300;

export { BOOKS_FOR_PAGE, GENRES_FOR_PAGE, AUTHORS_FOR_PAGE, RUSSIAN_ALPHABET, DAYS, MONTHS, YEARS, GENDER, MONTH_NAMES, API_URL, PRERENDER_PAGES, DEV_ABSOLUTE_URL, SERIES_FOR_PAGE, REVALIDATE_TIME, config };
